<template>
    <div class="alert alert-warning mt-2 py-2 px-3" role="alert">
        <h6>Hello From Custom Workflow Component! Counter: {{counter}}</h6>
        <div>
            <button class="btn btn-sm btn-primary" @click="counter++">Click!</button>
        </div>
        <div><b>Item ID</b>: {{props.itemId}}</div>
        <div><b>Step ID</b>: {{props.stepId}}</div>
    </div>
</template>

<script setup>   
    import { ref, defineProps } from 'vue';

    const props = defineProps({
        itemId: Number,
        stepId: Number
    });

    const counter = ref(0);
</script>